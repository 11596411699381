:root {
  --palette-amount: 0;
}

html, body
{
  margin: auto;
  text-align: center;
  background-color: #ddd;
}

main
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

#page-title
{
  display: inline-grid;
  grid-template-columns: auto auto;
  position: absolute;

  color: #000000;
  text-shadow: 3px 3px #ffffff;
  left: 5%;
  top: 5%;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#page-title h1
{
  margin: 0px;
}

#descriptive-text
{
  position: fixed;
  top: 9.5%;
  width: 120px;
  text-shadow: 1px 1px #fff;
  text-align: left;
  left: 5%;
  font-weight: bold;
}

#page-title img
{
  height: 60px;
  width: 60px;
  margin-left: 20px;
}

#definition
{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  width: 320px;
  text-align: left;
  padding: 25px;
  background-color: #ffffff;
}

#definition .word
{
  display: inline-block;
  margin-bottom: 0px;
}

#definition .noun
{
  display: inline-block;
  font-style: italic;
  padding-left: 17px;
}

#definition .definitions
{
  padding-left: 15px;
}

.hide
{
  display: none;
}

@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

#check-mark-icon
{
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#color-input
{
  display: inline-grid;
  grid-template-columns: auto auto;
}

#antipalette-content
{
  display: flex;
  flex-flow: column wrap;
}

.buttons
{
  font-size: 1.5vw;
}

.palette-color
{
  display: inline-table;
}

#color-input
{
  display: flex;
  vertical-align: middle;
  text-align: center;
  line-height: 38px;
}

#inputs
{
  display: inline-grid;
  grid-template-columns: auto auto;
  margin-top: 32px;
}

#inputs-form
{
  display: flow-root;
}

#inputs > div
{
  margin: 10px;
}

#swatch
{
  padding: 4px;
  background: #fff;
  display: inline-block;
  cursor: pointer;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

#swatch:hover
{
  border-color: hsl(0, 0%, 70%);
}

#popover
{
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#color
{
  width: 28px;
  height: 28px;
  border-radius: 2px;
}

#cover
{
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

@media screen and (min-width:320px) and (max-device-width: 481px)
{
  .buttons
  {
    font-size: 2vw;
  }

  button
  {
    margin-top: 35px !important;
    padding: 5px !important;
    font-size: 3vw !important;
  }

  #inputs-form
  {
    display: flow-root;
  }
}

#dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  border: 1px solid black;
}

.cancel-button
{
  background-color: #D82727;
  text-shadow: 1px 0px 0px #7f1a1a;

  border: 3px solid #a61c1c;
  box-shadow: 0px 5px 0px 0px #a61c1c;
}

.cancel-button:active
{
  cursor: pointer;
  box-shadow: none;
  transform: translateY(4px);

  border-color: #707070;
  background-color: #8c1b1b;
}

.cancel-button:hover:not(:active)
{
  background-color: #e63a3a;
}
