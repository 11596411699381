html, body
{
  margin: 0 !important;
  padding: 0 !important;
}

body,
p,
button
{
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.no-select
{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.center
{
  text-align: center;
  margin: auto;
}

button
{
  margin: 30px auto;
  padding: 10px 5%;
	border-radius: 32px;
	cursor: pointer;

	color: #fff;

  font-weight: bold;
	font-size: 1.1em;
  letter-spacing: 2px;
  line-height: 34px;
	text-decoration: none;
}

.home-button
{
  float: left;
  margin-left: 5%;

  text-shadow: 1px 0px 0px #086463;

	background-color: #0fb8b6;
  border: 3px solid #0e8d8b;
  box-shadow: 0px 5px 0px 0px #0e8d8b;
}

.home-button:active
{
  box-shadow: none;
  transform: translateY(4px);
}

.home-button:hover
{
  background-color: #1fc0bd;;
}

.right {
  float: right;
  margin-right: 5%;
}

.activate-button
{
  background-color: #71cc44;
  text-shadow: 1px 0px 0px #35611f;

  border: 3px solid #4c924c;
  box-shadow: 0px 5px 0px 0px #488f35;
}

.activate-button:active
{
  box-shadow: none;
  transform: translateY(4px);

  border-color: #707070;
  background-color: #adadad;
}

.activate-button:disabled
{
  box-shadow: none;
  transform: translateY(4px);

  color: #e8e8e8;
  border-color: #707070;
  background-color: #adadad;
}

.activate-button:disabled:hover
{
  cursor: not-allowed;
  background-color: #adadad !important;
}

.activate-button.loading:hover
{
  cursor: wait;
}

.activate-button:hover:not(:active)
{
  background-color: #7dce54;
}
