:root
{
  --dice-size: 10px;
  --translateZ: calc(var(--dice-size) / 2);
  --dice-transition-duration: 2s;
}

.dice
{
  position: absolute;
  width: var(--dice-size);
  height: var(--dice-size);
}

.dot
{
  position: absolute;
  width: calc(var(--dice-size) / 5);
  height: calc(var(--dice-size) / 5);
  margin:
    calc(var(--dice-size) / -10)
    calc(var(--dice-size) / 25)
    calc(var(--dice-size) / 25)
    calc(var(--dice-size) / -10);
  border-radius: 50%;
  background-color: black;
}

.wb .dot
{
  background-color: black;
}

.bw .dot
{
  background-color: white;
}

.side
{
  position: absolute;
  border-radius: calc(var(--dice-size) / 25);
  width: var(--dice-size);
  height: var(--dice-size);
  text-align: center;
  line-height: 4em;
}

.side.wb
{
  background-color: white;
}

.side.bw
{
  background-color: black;
}

.one .dot:not(.dot-1)
{
  display: none;
}

.two .dot:not(.dot-1):not(.dot-2)
{
  display: none;
}

.three .dot:not(.dot-1):not(.dot-2):not(.dot-3)
{
  display: none;
}

.four .dot-5, .four .dot-6
{
  display: none;
}

.five .dot-6
{
  display: none;
}

.two .dot-1,
.three .dot-1,
.four .dot-1,
.five .dot-1,
.six .dot-1
{
  top: 20%;
  left: 20%;
}

.two.flip .dot-2,
.three.flip .dot-3,
.four .dot-3,
.five .dot-3,
.six .dot-4
{
  top: 20%;
  left: 80%;
}

.one .dot-1,
.three .dot-2,
.five .dot-5
{
  top: 50%;
  left: 50%;
}

.two.flip .dot-1,
.three.flip .dot-1,
.four .dot-2,
.five .dot-2,
.six .dot-3
{
  top: 80%;
  left: 20%;
}

.two .dot-2,
.three .dot-3,
.four .dot-4,
.five .dot-4,
.six .dot-6
{
  top: 80%;
  left: 80%;
}

.six.flip .dot-2
{
  top: 20%;
  left: 50%;
}

.six.flip .dot-5
{
  top: 80%;
  left: 50%;
}

.six .dot-2
{
  top: 50%;
  left: 20%;
}

.six .dot-5
{
  top: 50%;
  left: 80%;
}

#color-button.wb
{
  color: black;
  text-shadow: 1px 0px 0px #dfdfdf;

	background-color: white;
  border: 3px solid #e6e6e6;
  box-shadow: 0px 5px 0px 0px #e6e6e6;
}

#color-button.bw
{
  color: white;
  text-shadow: 1px 0px 0px #464646;

	background-color: black;
  border: 3px solid #424242;
  box-shadow: 0px 5px 0px 0px #424242;
}

#color-button.mix
{
	background: linear-gradient(90deg, rgba(0,0,0,1) 50%, rgba(255,255,255,1) 50%);
  border: 3px solid #b4b4b4;
  box-shadow: 0px 5px 0px 0px #b4b4b4;
}

#color-button.mix div
{
  background: #FFFFFF;
  background: -webkit-linear-gradient(to right, #FFFFFF 50%, #000000 50%);
  background: -moz-linear-gradient(to right, #FFFFFF 50%, #000000 50%);
  background: linear-gradient(to right, #FFFFFF 50%, #000000 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#color-button:active
{
  box-shadow: none;
  transform: translateY(4px);
}
