html, body
{
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
}

#root, #home
{
  display: table;
  height: 100%;
  width: 100%;
}

.all-center
{
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

#app-logo
{
  width: 30vh;
}

h1
{
  font-size: 5vh;
}

h1 > span
{
  position: relative;
  top: -4vh;
  color: transparent;

  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: white;
  text-decoration-thickness: 3px;
}

#rubber-band-balls-link
{
  color: white;
  text-decoration: none;
}

.icon
{
  width: 125px;
}
